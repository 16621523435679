import Vuex from 'vuex'

/** VueX全局数据存取*/

// VueX 数据管理框架
// VueX 创建了一个全局唯一的仓库，用来存放全局的数据






/** localStorage 数据持久化操作 */
/*
// 持久保存数据到localStorage
const setLocalCartList = (state) => {
const { cartList } = state
const cartListString = JSON.stringify(cartList)

localStorage.cartList = cartListString // 把cartList保存到localStorage
}

// 从localStorage中取数据
const getLocaCartList = () => {

// { shopId: {shopName:'', productList:{ productId: {} }}}
try {
 return JSON.parse(localStorage.cartList); // 从localStorage取出cartList
} catch(e) {
 return {}
}

}

*/

export default Vuex.createStore({

  /** 在state中, 定义需全局使用的变量 */

  // state里边定义的变量，可以通过this.$store.state.XX在js代码中引用。
  state: {
    /*
    cartList: getLocaCartList(),

    --------> 拿数据：
    文件头引入：
    import { useStore } from 'vuex' // CompositionAPI 中使用 VueX

    const store = useStore();// CompositionAPI 中使用 VueX
    const cartList = store.state.cartList;

    */

    /*产品中心 --路由跳转控制*/
    productsRoute: {
      discard: true,
      index: 1
    },

    /*解决方案 --路由跳转控制*/
    solutionsRoute: {
      discard: true,
      index: 1
    },

    /*新闻资讯 --路由跳转控制*/
    newsRoute: {
      discard: true,
      index: 1
    },

    /*关于我们 --路由跳转控制*/
    aboutusRoute: {
      discard: true,
      index: 1
    },


    messageBoard: {
      content: '',
      name: '',
      phone: '',
      email: '',
      address: ''
    }



 },


 /** mutation -- 修改state中的数据 */
 // mutation 里面只允许写同步代码，不允许写异步代码
 // commit 和 mutation 做关联
 // dispatch 和 actions 做关联
 mutations: {

   /*
   changeShopName(state, payload) {
     const { shopId, shopName } = payload
     const shopInfo = state.cartList[shopId] || {
       shopName: '', productList:{}
     }
     shopInfo.shopName = shopName
     state.cartList[shopId] = shopInfo // 更新state
     setLocalCartList(state) // 持久保存state中数据到localStorage
   },
   --------> 调用：
   文件头引入：
   import { useStore } from 'vuex' // CompositionAPI 中使用 VueX

   const store = useStore() // CompositionAPI 中使用 VueX
   store.commit('changeShopName', { shopId, shopName });//更新数据

   */


   /*产品中心 --路由跳转控制*/
   changeProductsRoute(state, payload) {
     const { discard, index } = payload
     // 更新state
     state.productsRoute.discard = discard;
     state.productsRoute.index = index;

   },

   /*解决方案 --路由跳转控制*/
   changeSolutionsRoute(state, payload) {
     const { discard, index } = payload
     // 更新state
     state.solutionsRoute.discard = discard;
     state.solutionsRoute.index = index;

   },

   /*新闻资讯 --路由跳转控制*/
   changeNewsRouteRoute(state, payload) {
     const { discard, index } = payload
     // 更新state
     state.newsRoute.discard = discard;
     state.newsRoute.index = index;

   },

   /*关于我们 --路由跳转控制*/
   changeAboutusRoute(state, payload) {
     const { discard, index } = payload

     //console.log("changeAboutusRoute=== discard:"+discard)
     //console.log("changeAboutusRoute=== index:"+index)

     // 更新state
     state.aboutusRoute.discard = discard;
     state.aboutusRoute.index = index;

   },

   //修改留言板中的内容
   changeMessageBoard(state, payload) {
     const { content, name, phone, email, address } = payload

     // 更新state
     state.messageBoard.content = content;
     state.messageBoard.name = name;
     state.messageBoard.phone = phone;
     state.messageBoard.email = email;
     state.messageBoard.address = address;

   },

   cleanMessageBoard(state) {

     // 更新state
     state.messageBoard = {};//清空留言板

   },





  }


})
