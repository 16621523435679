import { createRouter, createWebHashHistory } from 'vue-router'

/** 页面路由*/

/* 路由理解：根据URL的不同，显示不同的内容 */

/*
 *  常用路由跳转方法总结：
 *
 *  1、router.back 回退到上一个页面
 *  2、router.push 跳转到指定页面
 *  3、<router-link :to> 跳转到指定页面
 *
 */


// 1. 定义路由组件.
// import Home from '../views/Home.vue'

// 2.定义一些路由
// 每个路由都需要映射到一个组件。
const routes = [
  {
    path: '/',
    name: 'Home', /*首页*/

    // component: Home
    // 异步加载路由
    component: () => import(/* webpackChunkName: "home" */ '../views/home/Home')
  },


  //产品中心
  {
    path: '/products',
    name: 'Products', /*产品中心*/
    component: () => import(/* webpackChunkName: "home" */ '../views/products/Products')
  },

  {
    path: '/productdetail_1',
    name: 'Product_1_Detail', /*产品详情页 1*/
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail/Product_1_Detail')
  },
  {
    path: '/productdetail_2',
    name: 'Product_2_Detail', /*产品详情页 2*/
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail/Product_2_Detail')
  },
  {
    path: '/productdetail_3',
    name: 'Product_3_Detail', /*产品详情页 3*/
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail/Product_3_Detail')
  },
  {
    path: '/productdetail_4',
    name: 'Product_4_Detail', /*产品详情页 4*/
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail/Product_4_Detail')
  },
  {
    path: '/productdetail_5',
    name: 'Product_5_Detail', /*产品详情页 4*/
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail/Product_5_Detail')
  },
  {
    path: '/productdetail_6',
    name: 'Product_6_Detail', /*产品详情页 4*/
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail/Product_6_Detail')
  },
  {
    path: '/productdetail_7',
    name: 'Product_7_Detail', /*产品详情页 4*/
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail/Product_7_Detail')
  },
  {
    path: '/productdetail_8',
    name: 'Product_8_Detail', /*产品详情页 4*/
    component: () => import(/* webpackChunkName: "home" */ '../views/productDetail/Product_8_Detail')
  },


  //解决方案
  {
    path: '/solutions',
    name: 'Solutions', /*解决方案*/
    component: () => import(/* webpackChunkName: "home" */ '../views/solutions/Solutions')
  },


  //案例中心
  {
    path: '/projectCases',
    name: 'ProjectCases', /*案例中心*/
    component: () => import(/* webpackChunkName: "home" */ '../views/projectCases/ProjectCases')
  },


  //创美优势 add 2022.7.28
  {
    path: '/advantages',
    name: 'Advantages', /*解决方案*/
    component: () => import(/* webpackChunkName: "home" */ '../views/advantages/Advantages')
  },


  //新闻
  {
    path: '/news',
    name: 'News', /*新闻资讯*/
    component: () => import(/* webpackChunkName: "home" */ '../views/news/News')
  },
  //行业新闻详情
  {
    path: '/news_hangye_detail_1',
    name: 'News_Hangye_Detail_1', /*行业新闻详情 1*/
    component: () => import('../views/news/News_1_Detail/News_Hangye_Detail_1')
  },
  {
    path: '/news_hangye_detail_2',
    name: 'News_Hangye_Detail_2', /*行业新闻详情 1*/
    component: () => import('../views/news/News_1_Detail/News_Hangye_Detail_2')
  },
  {
    path: '/news_hangye_detail_3',
    name: 'News_Hangye_Detail_3', /*行业新闻详情 1*/
    component: () => import('../views/news/News_1_Detail/News_Hangye_Detail_3')
  },
  {
    path: '/news_hangye_detail_4',
    name: 'News_Hangye_Detail_4', /*行业新闻详情 1*/
    component: () => import('../views/news/News_1_Detail/News_Hangye_Detail_4')
  },
  {
    path: '/news_hangye_detail_5',
    name: 'News_Hangye_Detail_5', /*行业新闻详情 1*/
    component: () => import('../views/news/News_1_Detail/News_Hangye_Detail_5')
  },
  //产品资讯详情
  {
    path: '/news_chanpin_detail_1',
    name: 'News_Chanpin_Detail_1', /*产品资讯详情 1*/
    component: () => import('../views/news/News_2_Detail/News_Chanpin_Detail_1')
  },
  {
    path: '/news_chanpin_detail_2',
    name: 'News_Chanpin_Detail_2', /*产品资讯详情 1*/
    component: () => import('../views/news/News_2_Detail/News_Chanpin_Detail_2')
  },
  {
    path: '/news_chanpin_detail_3',
    name: 'News_Chanpin_Detail_3', /*产品资讯详情 1*/
    component: () => import('../views/news/News_2_Detail/News_Chanpin_Detail_3')
  },


  //关于我们
  {
    path: '/aboutUs/',
    name: 'AboutUs', /*关于我们*/
    component: () => import(/* webpackChunkName: "home" */ '../views/aboutUs/AboutUs')
  },



]


// 3. 创建路由实例并传递 `routes` 配置
// 你可以在这里输入更多的配置，但我们在这里
const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHashHistory(),
  routes
})


/* 导航守卫：全局前置守卫 */
// router.beforeEach((to, from ,next) => {
//   const { isLogin } = localStorage;
//   const { name } = to;
//   const isLoginOrRegister = (name === "Login" || name === "Register");
//
//   (isLogin || isLoginOrRegister) ? next() : next({ name: 'Login'});// 未登录或注册，跳转到登录注册页；反之，放行。
//
// })

export default router
