
import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import 'normalize.css' // 浏览器兼容方案：让样式在所有浏览器中显示的样式保持一致。  安装命令：npm install normalize.css --save，然后import引入就行。 !!!!!!!

import './style/index.scss'// 设置全局基础样式。作用，规定后续适配中1rem到底为多少px。 !!!!!!!!!!

//引入element-plus
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';

//引入VueLazyLoad，实现图片懒加载 2021.8.10； 引入后整个网站无法显示
// import VueLazyLoad from 'vue-lazyload'


/**
 * 所有源代码的 入口文件
 */
createApp(App)

  .use(router) // 让应用支持路由Vue-Router。 路由是指根据url的不同，展示不同的内容。

  .use(store)  // 让应用支持状态管理Vuex。就是全局变量。

  .use(ElementPlus) //引入ElementPlus

  // .use(VueLazyLoad, {
  //   preLoad: 1,
  //   error: require('./assets/common/img-fail.png'),
  //   loading: require('./assets/common/img-loding.png'),
  //   attempt: 2,
  // })

  .mount('#app')

  //Vue.config.devtools = true;
